exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-giveaway-ideen-js": () => import("./../../../src/pages/giveaway-ideen.js" /* webpackChunkName: "component---src-pages-giveaway-ideen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ip-in-q-4-js": () => import("./../../../src/pages/ip-in-q4.js" /* webpackChunkName: "component---src-pages-ip-in-q-4-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-login-2-js": () => import("./../../../src/pages/login2.js" /* webpackChunkName: "component---src-pages-login-2-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-neuheiten-js": () => import("./../../../src/pages/neuheiten.js" /* webpackChunkName: "component---src-pages-neuheiten-js" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-themenwelten-js": () => import("./../../../src/pages/themenwelten.js" /* webpackChunkName: "component---src-pages-themenwelten-js" */),
  "component---src-pages-trends-js": () => import("./../../../src/pages/trends.js" /* webpackChunkName: "component---src-pages-trends-js" */),
  "component---src-pages-trikora-cart-js": () => import("./../../../src/pages/trikora-cart.js" /* webpackChunkName: "component---src-pages-trikora-cart-js" */),
  "component---src-pages-werbeartikel-bedrucken-js": () => import("./../../../src/pages/werbeartikel-bedrucken.js" /* webpackChunkName: "component---src-pages-werbeartikel-bedrucken-js" */),
  "component---src-pages-werbeartikel-js": () => import("./../../../src/pages/werbeartikel.js" /* webpackChunkName: "component---src-pages-werbeartikel-js" */),
  "component---src-pages-werbegeschenk-js": () => import("./../../../src/pages/werbegeschenk.js" /* webpackChunkName: "component---src-pages-werbegeschenk-js" */),
  "component---src-pages-werbegeschenke-mit-logo-js": () => import("./../../../src/pages/werbegeschenke-mit-logo.js" /* webpackChunkName: "component---src-pages-werbegeschenke-mit-logo-js" */),
  "component---src-pages-werbegeschenke-schweiz-js": () => import("./../../../src/pages/werbegeschenke-schweiz.js" /* webpackChunkName: "component---src-pages-werbegeschenke-schweiz-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-child-page-js": () => import("./../../../src/templates/childPage.js" /* webpackChunkName: "component---src-templates-child-page-js" */),
  "component---src-templates-childcategory-js": () => import("./../../../src/templates/childcategory.js" /* webpackChunkName: "component---src-templates-childcategory-js" */),
  "component---src-templates-parent-page-js": () => import("./../../../src/templates/parentPage.js" /* webpackChunkName: "component---src-templates-parent-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

